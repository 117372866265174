import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { mergeClasses } from '@/src/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors duration-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary:
          'bg-rebuild-primary flex shadow-sm justify-center items-center text-white font-bold uppercase rounded-[100px] text-base hover:bg-[#3a8251] tracking-wide disabled:opacity-75 disabled:cursor-not-allowed',
        dark: 'bg-rebuild-dark flex justify-center items-center text-white font-bold rounded-[100px] text-base hover:bg-black tracking-wide',
        outlineDark:
          'border shadow-sm border-rebuild-dark flex justify-center items-center text-rebuild-dark font-bold rounded-[100px] text-base hover:bg-dark hover:text-white tracking-wide',
        outlineGray:
          'border shadow-sm border-rebuild-gray-500 bg-white bg-white flex justify-center items-center text-rebuild-gray-500 font-bold rounded-[100px] text-base hover:bg-rebuild-gray-500 hover:text-white tracking-wide',
        ghost: 'bg-none text-base',
        social:
          'p-4 rounded-lg border border-rebuild-gray-500 shadow-sm hover:bg-neutral-50 active:bg-neutral-100',
        support:
          'bg-primary px-4 py-3 flex justify-center items-center text-white font-bold uppercase rounded-[100px] hover:bg-[#3A8251] tracking-wide disabled:bg-opacity-75 disabled:cursor-not-allowed',
      },
      size: {
        sm: 'h-9 rounded-md px-3',
        md: 'h-[46px] rounded-full px-3',
        lg: 'h-[56px] rounded-full px-8',
        icon: 'h-10 w-10',
      },
    },
  },
);
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={mergeClasses(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
